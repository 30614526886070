<template>
  <table class="print-table">
    <thead>
      <tr>
        <th width="24">序号</th>
        <th>付款及运输方式</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1</td>
        <td>
          <div class="prompt-box">
            <span>付款方式：</span>
            <span>现金</span>
            <span class="prompt-checkbox"></span>
            <span>15天</span>
            <span class="prompt-checkbox"></span>
            <span>30天</span>
            <span class="prompt-checkbox"></span>
            <span>含税</span>
            <span class="prompt-checkbox"></span>
            <span>其他协议</span>
            <span class="prompt-checkbox"></span>
          </div>
        </td>
      </tr>
      <tr>
        <td>2</td>
        <td>
          <div class="prompt-box">
            <span>运输方式：</span>
            <span>送货上门</span>
            <span class="prompt-checkbox"></span>
            <span>上门自提</span>
            <span class="prompt-checkbox"></span>
          </div>
        </td>
      </tr>
      <tr>
        <td>3</td>
        <td>质量处理：1%以内不作投诉，超过1%供应方需退换货处理</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.print-table {
  margin-top: 5px;
  width: 100%;
  border-collapse: collapse;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  .text-right {
    text-align: right;
  }
}
.print-table caption {
  padding-bottom: 5px;
  text-align: left;
  font-weight: bold;
}
.print-table th,
.print-table td {
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 2px;
  text-align: left;
  overflow-wrap: anywhere;
}
.print-table th {
  font-weight: normal;
  background: #e0e5eb;
}
.prompt-box {
  display: flex;
  align-items: center;
}
.prompt-checkbox {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 20px 0 5px;
  border: 1px solid #000;
}
</style>
