<template>
  <table class="print-table">
    <thead>
      <tr>
        <th width="24">序号</th>
        <th>备注要求如下</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in 5" :key="index">
        <td>{{ index + 1 }}</td>
        <td></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.print-table {
  margin-top: 5px;
  width: 100%;
  border-collapse: collapse;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  .text-right {
    text-align: right;
  }
}
.print-table caption {
  padding-bottom: 5px;
  text-align: left;
  font-weight: bold;
}
.print-table th,
.print-table td {
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 2px;
  text-align: left;
  overflow-wrap: anywhere;
}
.print-table th {
  font-weight: normal;
  background: #e0e5eb;
}
</style>
